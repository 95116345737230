
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./HenryChalfant.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import HenryChalfantPhoto from "../../../../res/chalfant/portrait.jpg"
import HenryChalfantPhoto1 from "../../../../res/Photos site/Henry Chalfant/HenriChalfant_Paris-PRINT_10.jpg"
import HenryChalfantPhoto2 from "../../../../res/Photos site/Henry Chalfant/Henry-Chalfant-03.jpg"
import HenryChalfantPhoto3 from "../../../../res/Photos site/Henry Chalfant/Photo henry chalfant 2.jpg"
import HenryChalfantPhoto4 from "../../../../res/Photos site/Henry Chalfant/2.jpg"
import HenryChalfantPhoto5 from "../../../../res/Photos site/Henry Chalfant/3.jpg"
import HenryChalfantPhoto6 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Henry Chalfant - Daze Bus Zeph.jpg"
import HenryChalfantPhoto7 from "../../../../res/Photos site/Photos Oeuvres Disponibles/HENRY CHALFANT_BIL REVOLT_1980.jpg"
import HenryChalfantPhoto8 from "../../../../res/Photos site/Photos Oeuvres Disponibles/HENRY CHALFANT_CRASH NOD 1980.jpg"
import HenryChalfantPhoto9 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Henry Chalfant, Dondi White Children of the grave, 1980 HD.jpg"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "HenryChalfant",
  name: "HenryChalfant",
  description:"Henry Chalfant est un photographe et vidéaste américain né en 1940 et spécialisé dans la photo du graffiti et de la culture hip-hop de manière générale. Il a réalisé de nombreux clichés historiques de trains ou de murs. En véritable détective à l'affût de la plus belle prise de vue, Henry Chalfant a pu capturer à tout jamais des oeuvres éphémères avant qu’elles ne soient détruites ou recouvertes. Il a notamment photographié le travail de Lee Quinones sur deux wagons de métro dans le Bronx en 1977, c’est à ce moment-là que son intérêt pour la subculture s’est accru. Ses photos sont désormais exposées dans les collections permanentes du Metropolitan Museum of Art à New York et au Carnegie Institute de Pittsburgh. ",
  pdp: HenryChalfantPhoto,
  alt_pdp: "Photo de profil de HenryChalfant.",
  photos: [
    { src: HenryChalfantPhoto5, name: "HenryChalfant" },
    { src: HenryChalfantPhoto2, name: "HenryChalfant" },
    { src: HenryChalfantPhoto3, name: "HenryChalfant" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: HenryChalfantPhoto6, name: "Henry Chalfant", artist:"Henry Chalfant\n(né en 1940)", title:"Dondi - Chlidren of the Grave Return Part 2, 1980", desc1: "Photographie", desc2 :"Photography", desc3: "28 x 152 cm\n42 x 163,5 cm (encadrée)", desc4:" 11 x 59 7/8 in.\n16 1/2 x 64 3/8 in. (framed)", desc5:"Signée sur le devant, datée \"80\" et numérotée \"4/9\".", desc6:"Signed on the front, dated \"80\" and numbered \"4/9\".", desc7: "- Collection particulière.", desc8:"<h4><i>- Nos Fantômes</i> - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.\n- <i>Art in the Streets</i>, MOCA curated by Jeffrey Deitch, Roger Castman and Aaron Rosa. Los Angeles, 17 Avril - 8 Août, 2011 (catalogue).</h4>" },
    { src: HenryChalfantPhoto7, name: "Henry Chalfant", artist:"Henry Chalfant\n(né en 1940)", title:"Daze Bus Zeph, 1980", desc1: "Photographie", desc2:"Photography\n\nKodak Professional\nEndura Metallic Paper", desc3:"27,7 x 151 cm\n31,5 x 155 cm (encadrée)", desc4: "10 3/4 x 59 1/4 in.\n12 1/4 x 61 in. (framed)", desc5:"Numérotée \"4/9\"", desc6:"Numbered \"4/9\"", desc7:"- Acquis auprès de l'artiste" },
    { src: HenryChalfantPhoto8, name: "Henry Chalfant", artist:"Henry Chalfant\n(né en 1940)", title:"Bil Revolt, 1980", desc1: "Photographie", desc2:"Photography\n\nKodak Professional\nEndura Metallic Paper", desc3:"29,5 x 152,4 cm", desc4:"11 5/8 x 60 in.", desc5:"Numérotée \"3/9\"", desc6:"Numbered \"3/9\"", desc7:"- Acquis auprès de l'artiste." },
    { src: HenryChalfantPhoto9, name: "Henry Chalfant", artist:"Henry Chalfant\n(né en 1940)", title:"Crash Nod, circa 1980", desc1: "Photographie", desc2:"Photography\n\nKodak Professional\nEndura Metallic Paper", desc3:"31,24 x 152,4 cm", desc4: "13 3/10 x 60 in.", desc5:"Numérotée \"4/9\"", desc6:"Numbered \"4/9\"", desc7:"- Acquis auprès de l'artiste." },
  ],
};

const HenryChalfant = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
       <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>HENRY CHALFANT <h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <h3 className={NameWrapper} >Né en 1940</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p>- Bachelor’s Degree - Stanford University, Stanford, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Henry Chalfant : Art vs. Transit, Bronx Museum, New York, USA, September 25th, 2019 - March 8th, 2020.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Henry Chalfant: 1980, Speerstra Gallery. Paris, France, October 7th - November 10th.</p>
          <p>- Henry Chalfant: 1980, Solo installation by Eric Firestone Gallery at the Paris Photo photography fair. Paris, France November 9th - 12th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Henry Chalfant, Steven Kasher Gallery. New York, USA, January 30th - March 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Henry Chalfant: Art is not a Crime, Speerstra Gallery Paris. Paris, France, December 12th - 29th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- 10 Years: 1977 - 1987, Prosper Tokyo. Tokyo, Japan; The Prosper Gallery, New York, USA. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Untitled, Addison - Ripley Gallery. Washington, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Instituto de Cultura Norteamericana. Barcelona, Spain.</p>
          <p>- Riverside Studios. London, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Canton Art Institute. Canton, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- Graffiti in New York, OK Harris Works of Art. New York, USA. </p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Concrete to Canvas, West Chelsea Contemporary, Austin, TX, USA, November 6th - January 2nd.</p>
          <p>- New York Street Style : création des années 80 - Ghost Galerie, Paris, juillet - septembre 2022.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <p>- Icons Vandals, West Chelsea Contemporary, Austin, TX, USA, May 15th - July 11th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Hip-Hop, Un Âge d’Or, Musée d’Art Contemporain. Marseille, France, May 13th, 2017 - January 8th, 2018.</p>
          <p>- Gordon Matta-Clark: Anarchitect, Bronx Museum of Arts. New York, USA, November 8th, 2017 - April 8th, 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Epoxy, Rose Béton Festival, Musée des Abattoirs. Toulouse, France, June 15th - August 28th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- The Bridges of Graffiti, curated by Fondazione de Mitri and Mode2, Venice Biennial. Venice, Italy, May 9th - November 22th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Art in the Streets, MOCA. Los Angeles, USA, April 17th - August 8th. (catalogue) 2010</p>
          <p>- Né dans la Rue - Graffiti, Fondation Cartier pour l’Art Contemporain. Paris, France, July 7th, 2009 - January 10th, 2010. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Whole in the Wall, Halenbeck Gallery. New York, USA, May 29th - June 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Hip Hop exhibit at the Experience Music Project. Seattle, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Hip Hop: A Cultural Expression, The Rock and Roll Hall of Fame. Cleveland, Ohio, USA.</p>
          <p>- Art of the American Century Part II, Whitney Museum of American Art. New York, USA.</p>
          <p>- Urban Mythologies, organized by Lydia Yee and Betti-Sue Hertz, The Bronx Museum of the Arts. New York, USA, April 8th - September 5th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- New York Graffiti Art : Coming from the Subway (Collection Speerstra), Groninger Museum, Groningen, The Netherlands, October 4th - January 10th (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Since the Harlem Renaissance: 50 years of Afro-American Art, Center Gallery of Bucknell University. Lewisburgh, USA (travelling exhibition), April 13th, 1984 - November 1st, 1985. (catalogue)</p>
          <p>- Content: A Contemporary Focus, 1974-1984, Hirshhorn Museum, Washington, USA, October 4th, 1984 - January 6th, 1985.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Art: A Show of Comics, The Whitney Museum of American Art, Downtown. New York, USA, August 12th - 26th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- New York/New Wave, P.S.1, Institute for Art and Urban ressources. Long Island City, February 15th - April 5th.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>PRIVATE COLLECTIONS</p>
          <br />
          <p>- Metropolitan Museum of Art, New York, USA.</p>
          <p>- Carnegie Institute, Pittsburgh, USA.</p>
          <p>- Groninger Museum, Groningen, The Netherlands.</p>
          <p>- Bronx Museum, New York, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>LITERATURE</p>
          <br />
          <p>- CHALFANT H. and JENKINS S., Training Days, Thames and Hudson, London, United Kingdom 2015.</p>
          <p>- Art in the Streets, Los Angeles Museum of Contemporary Art, Rizzoli International Publications Inc., Los Angeles, USA, 2011</p>
          <p>- Né dans la Rue: Graffiti, Fondation Cartier pour l’Art Contemporain. Paris, France, July 2009.</p>
          <p>- Henry CHalfant, 10 Years: 1977 - 1987, Prosper Tokyo. Tokyo, 2002.</p>
          <p>- Urban Mythologies: The Bronx Represented since the 1960s, edited by John Alan Farmer, Bronx Museum of the Arts, New York, USA, 1999.</p>
          <p>- New York Graffiti Art: Coming from the Subway, edited by Benjamin and Partners in association with the Groninger Museum, Groningen, The Netherlands, 1992.</p>
          <p>- CHALFANT H. and PRIGOFF J., Spray Can, Thames and Hudson, London, United Kingdom & New York, USA, 1987.</p>
          <p>- Since the Harlem Renaissance: 50 years of Afro-American Art, Joseph Jacobs with the Center Gallery at Bucknell University, Lewisburgh, USA, 1985.</p>
          <p id="end">- CHALFANT H. and COOPER M., Subway Art, Thames and Hudson, London, United Kingdom, 1984.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default HenryChalfant;