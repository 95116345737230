// extracted by mini-css-extract-plugin
export var ArtistDescription = "HenryChalfant-module--ArtistDescription--tb5dT";
export var ArtistInfos = "HenryChalfant-module--ArtistInfos--Y+YgQ";
export var ButtonWrapper = "HenryChalfant-module--ButtonWrapper --sHBHH";
export var CardWrapper = "HenryChalfant-module--CardWrapper--gJtLZ";
export var CarrouselWrapper2 = "HenryChalfant-module--CarrouselWrapper2--UYJbe";
export var Citations = "HenryChalfant-module--Citations--EZZdi";
export var DescriptionWrapper = "HenryChalfant-module--DescriptionWrapper--e+UN6";
export var ImageWrapper = "HenryChalfant-module--ImageWrapper--RNnbF";
export var LinkWrapper = "HenryChalfant-module--LinkWrapper--wuuOy";
export var MobileProtrait = "HenryChalfant-module--MobileProtrait--Saewm";
export var More = "HenryChalfant-module--More--bkXFY";
export var MoreButton = "HenryChalfant-module--MoreButton--2x8M-";
export var NameWrapper = "HenryChalfant-module--NameWrapper--kzKeX";
export var PdpWrapper = "HenryChalfant-module--PdpWrapper--OW+wg";
export var PhotosWrapper = "HenryChalfant-module--PhotosWrapper--5uuIX";
export var ProfilWrapper = "HenryChalfant-module--ProfilWrapper--eoX9I";
export var TitleWrapper = "HenryChalfant-module--TitleWrapper--N2bWe";
export var Wrapper = "HenryChalfant-module--Wrapper--w-+A3";